<template>
<div>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{$t("AccountSetting.General")}}</span>
      </template>

      <account-setting-general
        v-if="options"
        :general-data="options"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{$t("AccountSetting.ChangePassword")}}</span>
      </template>

      <account-setting-password/>
    </b-tab>

    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('AccountSetting.ChangePIN')}}</span>
      </template>

      <account-setting-pin/>
    </b-tab>
  </b-tabs>
</div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingPin from './AccountSettingPin.vue'
import { mapMutations } from "vuex";

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingPin,
  },
  data() {
    return {
      options: {},
    }
  },
  beforeCreate() {
    this.$http.get(`/api/${this.$router.currentRoute.params.prefix}/me`).then(res => {this.options = res.data })
  },
  mounted() {
    this.UPDATE_FLAG_SHOW_OVERLAY(false);
  },
  methods: {
    ...mapMutations('app', [
      'UPDATE_FLAG_SHOW_OVERLAY'
    ]),
  }
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-sweetalert.scss';
</style>

